var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"b48d046dec5212fed53e8ec1c1f3057078bd98fb"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const environment = process.env.NEXT_PUBLIC_SENTRY_ENV || 'development'
// known issue of integration between Chrome 74.0.3729 and Sentry, which has produced 100k errors in our Sentry logs
// Open issue on Sentry is here: https://github.com/getsentry/sentry-javascript/issues/3388
const isAffectedByIssue3388 = navigator.userAgent.includes('Chrome/74.0.3729')

Sentry.init({
  integrations: [
    new Sentry.Integrations.TryCatch({
      requestAnimationFrame: !isAffectedByIssue3388,
      eventTarget: !isAffectedByIssue3388,
    }),
  ],
  ignoreErrors: [
    // Non-actionable error messages
    'Network Error',
    'GEO-IP lookup: Call to getipcountry.zoe.net failed with Network Error',
    // Ignores spammy errors that seem to be caused by problematic integration between Next.js and Sentry (https://github.com/getsentry/sentry-javascript/issues/3440)
    'Non-Error promise rejection captured',
    'Hydration failed because the initial UI does not match what was rendered on the server.',
    'There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root will switch to client rendering.',
    'Text content does not match server-rendered HTML.',
    'This Suspense boundary received an update before it finished hydrating. This caused the boundary to switch to client rendering. The usual way to fix this is to wrap the original update in startTransition',
    /Minified React error #(\d+); visit https:\/\/reactjs\.org\/docs\/error-decoder\.html\?invariant=\d+ for the full message or use the non-minified dev environment for full errors and additional helpful warnings\./,
    // GTM/GA errors
    /Can't find variable: (_tfa|twq|ttq|PaymentAutofillConfig)/,
    /(_tfa|twq|ttq) is not defined/,
    // M&S tracking errors
    'mnsInlineData',
    // Browser / browser extension errors
    'Extension context invalidated.',
    /.*@webkit-masked-url.*/,
    /window\._?webkit(Namespace)?\.messageHandlers/,
    '_chromeNamespace',
    // onetrust errors
    "TypeError: undefined is not an object (evaluating 't.Domain')",
    'SyntaxError: Unexpected token \'j\', "jsonFeed({"... is not valid JSON',
    "TypeError: undefined is not an object (evaluating 'r.DomainData')",
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Ignore Google flakiness
    /\/(gtm|ga|analytics)\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Firefox extensions
    /^resource:\/\//i,
    // Other plugins
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0,
  environment: environment,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
